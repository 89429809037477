var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{staticClass:"data-table_type_documents",attrs:{"headers":_vm.preparedHeaders,"items":_vm.preparedDocuments,"use-item-slot":true,"checkbox":_vm.selectedAll,"show-select":_vm.allowPayment,"use-header-checkbox":_vm.allowPayment},on:{"checkbox":function () {
      if (!_vm.selectedAll || _vm.selectedRows.some(function (state) { return !state; })) {
        _vm.selectedAll = true;
      } else {
        _vm.selectedAll = false;
      }
      _vm.selectedRows = Array(_vm.docsLength).fill(_vm.selectedAll);
    }},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return [_c('div',{staticClass:"data-table__head-content"},[_c('x-dropdown-with-date-picker',{attrs:{"dates":_vm.dates},on:{"update:dates":function($event){_vm.dates=$event}}}),_c('x-dropdown-with-radio-buttons',{attrs:{"activator-text":_vm.currentDocTypeBySenderStatus,"items":_vm.docTypesBySenderStatus,"item":_vm.currentDocTypeBySenderStatusIndex},on:{"update:item":function($event){_vm.currentDocTypeBySenderStatusIndex=$event}},scopedSlots:_vm._u([{key:"firstListItem",fn:function(){return [_c('span',{staticClass:"dropdown__title"},[_vm._v("Тип документа")])]},proxy:true}])}),_c('x-dropdown-with-radio-buttons',{directives:[{name:"show",rawName:"v-show",value:(!_vm.currentDocTypeBySenderStatusIndex),expression:"!currentDocTypeBySenderStatusIndex"}],attrs:{"activator-text":_vm.currentDocTypeByPaymentStatus,"items":_vm.docTypesByPaymentStatus,"start-active-radio-index":1,"item":_vm.currentDocTypeByPaymentStatusIndex},on:{"update:item":function($event){_vm.currentDocTypeByPaymentStatusIndex=$event}},scopedSlots:_vm._u([{key:"firstListItem",fn:function(){return [_c('span',{staticClass:"dropdown__title"},[_vm._v("Статус")])]},proxy:true}])})],1),(_vm.allowPayment)?_c('div',{staticClass:"data-table__head-content"},[(_vm.totalSelectedRows)?_c('span',{staticClass:"align-self-center text-h6 primary--text"},[_vm._v(" "+_vm._s(("Выбрано записей: " + _vm.totalSelectedRows + " На сумму: "))+" "+_vm._s(_vm._f("prepareMoney")(_vm.selectedRowsSum))+" ")]):_vm._e(),_c('x-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.usePayment),expression:"usePayment"}],attrs:{"disabled":!_vm.totalSelectedRows}},[_vm._v(" Оплатить ")])],1):_vm._e()]},proxy:true},{key:"item",fn:function(ref){
    var ref_item = ref.item;
    var index = ref_item.index;
    var item = ref_item.item;
return [_c('tr',{class:[
        'data-table__text',
        'data-table__text_cursor_default',
        _vm.selectedRows[index] && 'data-table__text_active_base' ]},[(_vm.allowPayment)?_c('td',[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"hide-details":""},model:{value:(_vm.selectedRows[index]),callback:function ($$v) {_vm.$set(_vm.selectedRows, index, $$v)},expression:"selectedRows[index]"}})],1):_vm._e(),_vm._l((item),function(value,header){return _c('td',{key:("" + header),class:_vm.tdClassesByHeaders[header]},[(header.endsWith('type'))?_c('div',{staticClass:"d-flex flex-column"},[_c('span',[_vm._v(" "+_vm._s(_vm.humanDocTypeByValue[value[0]] || value[0])+" "+_vm._s(value[1] && ("№ " + (value[1])))+" ")]),_c('span',{staticClass:"secondary--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(value[2]))+" ")])]):(header.endsWith('analytics'))?[_vm._v(" "+_vm._s(value || "—")+" ")]:(header.endsWith('theme'))?_c('span',{class:[
            value &&
              [
                'd-block',
                'align-center',
                'justify-center',
                'icon',
                'icon_size_md',
                'icon_color_base',
                'rounded',
                'background--text',
                'text--lighten-5',
                'text-uppercase',
                'doc-themes'
              ].join(' ') ]},[_vm._v(" "+_vm._s(value || "—")+" ")]):(header.startsWith('sum'))?_c('span',{staticClass:"d-block text-right"},[_vm._v(" "+_vm._s(_vm._f("prepareMoney")(value))+" ")]):_c('action-dropdown',{on:{"send":function($event){return _vm.propagateDocumentEvt($event, index)},"download":function($event){return _vm.propagateDocumentEvt($event, index)}}})],2)})],2)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }